/* =================================
* jQuery scroll v1.0 / Copyright © 2014 Spab Rice
* All rights reserved.
================================= */
(function($){
'use strict';

var originalheaderheight = jQuery('header').height();

jQuery(window).load(function($) {	

	var scrollspeed = 1000;
	var firstBodyElement = '#section-home';
	var borderWidthHeight = parseInt(jQuery("#page-content").css("padding-top"))-2;

			
	jQuery('body').on('click', '.scroll-to', function() {
		var href = jQuery(this).attr('href');
		var target = jQuery(this).attr('data-target');
		if (href.charAt(0) == '#') { target = href;  }
		
		if (href == firstBodyElement) { 
			jQuery('html,body').animate({scrollTop: 0}, scrollspeed, 'easeInOutQuart'); 
		} else if (jQuery(target).length > 0) {
			jQuery('html,body').animate({ scrollTop: jQuery(target).offset().top-borderWidthHeight}, scrollspeed, 'easeInOutQuart');
		}
		
		return false;
	});
	
	
	
	function activateNav() {
		var minimum = '';
		var active = '';
		
		jQuery('#main-nav ul > li').each(function(index){ 
			var href = jQuery(this).find('a').attr('href');
			var target = jQuery(this).find('a').attr('data-target');
			if (href.charAt(0) == '#') { target = href;  }
			
			if (jQuery(target).length > 0 && jQuery(target).position().top-originalheaderheight <= jQuery(document).scrollTop()) { 
				active = target; 
			} 
			//if (jQuery(href).position().top < 500) { active = '#home'; }
		});
							
		if (active) { 
			var visible = jQuery(active).visible(true); 
			if (visible) { 
				jQuery('#main-nav ul > li, #traditional-nav ul > li').removeClass('current-menu-item');
				jQuery('#main-nav ul > li a[href="'+active+'"], #traditional-nav ul > li a[href="'+active+'"]').parent('li').addClass('current-menu-item');
			} else {
				jQuery('#main-nav ul > li, #traditional-nav ul > li').removeClass('current-menu-item');
			}
		} else {
			jQuery('#main-nav ul > li, #traditional-nav ul > li').removeClass('current-menu-item');
			jQuery('#main-nav ul > li:first-child, #traditional-nav ul > li:first-child').addClass('current-menu-item');
			
		}
		
	}
	
	if (jQuery('.hp-nav-menu').length) {
		activateNav();
		jQuery(window).scroll(function() { activateNav(); });
	}
	
		
});

})(jQuery)