/*

MINIFIED VERSION FORM DEMO PURPOSE

*/
function setProportion(e, t, n, r) {
    var i = getProportion(e, t, n, r);
    e.find(".vid-bg").width(i * t);
    e.find(".vid-bg").height(i * n);
    e.find(".vid-bg video").width(i * t);
    e.find(".vid-bg video").height(i * n);
    var s = (e.width() >> 1) - (e.find(".vid-bg video").width() >> 1) | 0;
    var o = (e.height() >> 1) - (e.find(".vid-bg video").height() >> 1) | 0;
    e.find(".vid-bg video").css({
        left: s,
        top: o
    })
}

function getProportion(e, t, n, r) {
    var i = jQuery(window).width();
    var s = jQuery(window).height();
    var o = i / s;
    var u = e.width();
    var a = e.height();
    var f = u / a;
    var l = t / n;
    var c = a / n;
    if (f >= l) {
        c = u / t
    } else if (r && a < jQuery(window).height()) {
        c = jQuery(window).height() / n
    }
    return c
}

function parallaxVideo(e) {
    var t = e.visible(true);
    if (t) {
        var n = parseInt(jQuery(e).offset().top);
        var r = n - jQuery(window).scrollTop();
        var i = -(r / 1.5);
        var s = i + "px";
        e.find(".vid-bg video").css({
            top: s
        })
    }
}(function(e) {
    e.fn.extend({
        bgVideo: function(e) {
            return this.each(function(e) {
                var t = jQuery(this);
                var n = {
                    videofile: t.data("videofile"),
                    videowidth: t.data("videowidth"),
                    videoheight: t.data("videoheight"),
                    videoposter: t.data("videoposter"),
                    videoparallax: t.data("videoparallax"),
                    videooverlaycolor: t.data("videooverlaycolor"),
                    videooverlayopacity: t.data("videooverlayopacity"),
                    videosound: t.data("sound")
                };
                if (t.css("position") !== "absolute") {
                    t.css({
                        position: "relative"
                    })
                }
                var r = "";
                if (n.videooverlaycolor) {
                    overlay = '<div class="vid-overlay" style="position:absolute;width:100%;height:100%;top:0;left:0;background:' + n.videooverlaycolor + ';z-index:-2;-webkit-backface-visibility: hidden;-webkit-transform: translateZ(0);" ></div>'
                }
                r += '<div class="vid-main" style="position:absolute;width:100%;height:100%;top:0;left:0;overflow:hidden">';
                r += '<div class="vid-bg" style="position:absolute;width:100%;height:100%;top:0;left:0;z-index:-10;background: url(' + n.videoposter + ') center center; background-size: cover;">';
                if (jQuery(window).width() > 1024) {
                    
                    r += "</div></div>";
                    if (n.videosound) {
                        r += '<a href="#" class="mute-video" style="position: absolute;z-index:50; bottom:20px;left:50%;margin-left: -10px;color:#ffffff;display:block;width: 20px;height: 20px;"><i class="fa fa-volume-up fa-fw"></i></a>'
                    } else {}
                }
                t.prepend(overlay);
                t.append(r);
                t.find(".vid-overlay").css({
                    opacity: n.videooverlayopacity
                });
                t.find(".vid-bg video").fadeIn(1e3);
                if (jQuery(window).width() > 1024) {
                    setProportion(t, n.videowidth, n.videoheight, n.videoparallax);
                    jQuery(window).resize(function() {
                        setProportion(t, n.videowidth, n.videoheight, n.videoparallax);
                        parallaxVideo(t)
                    })
                }
                if (n.videoparallax) {
                    parallaxVideo(t);
                    jQuery(window).scroll(function() {
                        parallaxVideo(t)
                    })
                }
            })
        }
    })
})(jQuery);
jQuery("body").on("click", ".mute-video", function() {
    var e = jQuery(this).siblings(".vid-bg").find("video").attr("id");
    var t = document.getElementById(e);
    if (t.muted == false) {
        t.muted = true;
        jQuery(this).find("i").removeClass("fa-volume-up");
        jQuery(this).find("i").addClass("fa-volume-off")
    } else {
        t.muted = false;
        jQuery(this).find("i").removeClass("fa-volume-off");
        jQuery(this).find("i").addClass("fa-volume-up")
    }
    return false
})

function moveParallax(e) {
    var t = jQuery(e).visible(true);
    if (t) {
        var n = jQuery(e).data("imagewidth");
        var r = jQuery(e).data("imageheight");
        var i = jQuery(e).data("speed");
        var s = jQuery(e).width();
        var o = jQuery(e).offset().left;
        var u = jQuery(window).width();
        var a = jQuery(window).height();
        if (n && u - 200 > s) {
            preLeft = o - Math.round(u / 2) + Math.round(s / 2);
            leftPos = Math.round((n - u) / 2);
            leftPos = preLeft + -leftPos + "px"
        } else {
            leftPos = "50%"
        }
        if (r && r > a) {
            topPos = Math.ceil((r - a) / 2)
        } else {
            topPos = "0"
        }
        if (i) {} else {
            i = .3
        }
        var f = parseInt(jQuery(e).offset().top);
        var l = f - jQuery(window).scrollTop();
        var c = l * i - topPos;
        var i = leftPos + " " + c + "px";
        jQuery(e).css({
            backgroundPosition: i
        })
    }
}
jQuery(window).load(function() {
    setTimeout(function() {
        jQuery("html,body").animate({
            scrollTop: jQuery(window).scrollTop() + 1
        }, 5, function() {
            jQuery("html,body").animate({
                scrollTop: jQuery(window).scrollTop() - 1
            }, 5)
        })
    }, 2e3)
});
(function(e) {
    e.fn.extend({
        parallax: function(e) {
            return this.each(function() {
                var e = jQuery(this);
                setTimeout(function() {
                    var t = new Image;
                    t.onload = function() {
                        imgH = this.height;
                        imgW = this.width;
                        e.data("imagewidth", imgW);
                        e.data("imageheight", imgH)
                    };
                    t.src = e.data("parallax-image")
                }, 500);
                if (jQuery(window).width() > 1024) {
                    e.css({
                        background: "url(" + e.data("parallax-image") + ") center fixed",
                        "background-size": "cover"
                    });
                    setTimeout(function() {
                        moveParallax(e)
                    }, 500);
                    jQuery(window).scroll(function() {
                        moveParallax(e)
                    })
                } else {
                    e.css({
                        background: "url(" + e.data("parallax-image") + ") center fixed",
                        "background-size": "cover"
                    })
                }
            })
        }
    })
})(jQuery)
jQuery.fn.extend({
    count: function(e, t, n, r) {
        function o() {
            var r = e += n;
            i.text(r);
            if (n < 0 && t >= e || n > 0 && e >= t) {
                i.text(t);
                clearInterval(s)
            }
        }
        var i = this,
            s;
        if (e - t > 0) n = -1;
        e -= n;
        s = setInterval(o, r || 80)
    }
})
if (typeof Object.create !== "function") {
    Object.create = function(e) {
        function t() {}
        t.prototype = e;
        return new t
    }
}(function(e, t, n) {
    var r = {
        init: function(t, n) {
            var r = this;
            r.$elem = e(n);
            r.options = e.extend({}, e.fn.owlCarousel.options, t, r.$elem.data());
            r.userOptions = t;
            r.loadContent()
        },
        loadContent: function() {
            function r(e) {
                var n, r = "";
                if (typeof t.options.jsonSuccess === "function") {
                    t.options.jsonSuccess.apply(this, [e])
                } else {
                    for (n in e.owl) {
                        if (e.owl.hasOwnProperty(n)) {
                            r += e.owl[n].item
                        }
                    }
                    t.$elem.html(r)
                }
                t.logIn()
            }
            var t = this,
                n;
            if (typeof t.options.beforeInit === "function") {
                t.options.beforeInit.apply(this, [t.$elem])
            }
            if (typeof t.options.jsonPath === "string") {
                n = t.options.jsonPath;
                e.getJSON(n, r)
            } else {
                t.logIn()
            }
        },
        logIn: function() {
            var e = this;
            e.$elem.data({
                "owl-originalStyles": e.$elem.attr("style"),
                "owl-originalClasses": e.$elem.attr("class")
            });
            e.$elem.css({
                opacity: 0
            });
            e.orignalItems = e.options.items;
            e.checkBrowser();
            e.wrapperWidth = 0;
            e.checkVisible = null;
            e.setVars()
        },
        setVars: function() {
            var e = this;
            if (e.$elem.children().length === 0) {
                return false
            }
            e.baseClass();
            e.eventTypes();
            e.$userItems = e.$elem.children();
            e.itemsAmount = e.$userItems.length;
            e.wrapItems();
            e.$owlItems = e.$elem.find(".owl-item");
            e.$owlWrapper = e.$elem.find(".owl-wrapper");
            e.playDirection = "next";
            e.prevItem = 0;
            e.prevArr = [0];
            e.currentItem = 0;
            e.customEvents();
            e.onStartup()
        },
        onStartup: function() {
            var e = this;
            e.updateItems();
            e.calculateAll();
            e.buildControls();
            e.updateControls();
            e.response();
            e.moveEvents();
            e.stopOnHover();
            e.owlStatus();
            if (e.options.transitionStyle !== false) {
                e.transitionTypes(e.options.transitionStyle)
            }
            if (e.options.autoplay === true || e.options.autoplay === "true") {
                e.options.autoplay = 5e3
            }
            e.play();
            e.$elem.find(".owl-wrapper").css("display", "block");
            if (!e.$elem.is(":visible")) {
                e.watchVisibility()
            } else {
                e.$elem.css("opacity", 1)
            }
            e.onstartup = false;
            e.eachMoveUpdate();
            if (typeof e.options.afterInit === "function") {
                e.options.afterInit.apply(this, [e.$elem])
            }
        },
        eachMoveUpdate: function() {
            var e = this;
            if (e.options.lazyLoad === true) {
                e.lazyLoad()
            }
            if (e.options.autoHeight === true) {
                e.autoHeight()
            }
            e.onVisibleItems();
            if (typeof e.options.afterAction === "function") {
                e.options.afterAction.apply(this, [e.$elem])
            }
        },
        updateVars: function() {
            var e = this;
            if (typeof e.options.beforeUpdate === "function") {
                e.options.beforeUpdate.apply(this, [e.$elem])
            }
            e.watchVisibility();
            e.updateItems();
            e.calculateAll();
            e.updatePosition();
            e.updateControls();
            e.eachMoveUpdate();
            if (typeof e.options.afterUpdate === "function") {
                e.options.afterUpdate.apply(this, [e.$elem])
            }
        },
        reload: function() {
            var e = this;
            t.setTimeout(function() {
                e.updateVars()
            }, 0)
        },
        watchVisibility: function() {
            var e = this;
            if (e.$elem.is(":visible") === false) {
                e.$elem.css({
                    opacity: 0
                });
                t.clearInterval(e.autoplayInterval);
                t.clearInterval(e.checkVisible)
            } else {
                return false
            }
            e.checkVisible = t.setInterval(function() {
                if (e.$elem.is(":visible")) {
                    e.reload();
                    e.$elem.animate({
                        opacity: 1
                    }, 200);
                    t.clearInterval(e.checkVisible)
                }
            }, 500)
        },
        wrapItems: function() {
            var e = this;
            e.$userItems.wrapAll('<div class="owl-wrapper">').wrap('<div class="owl-item"></div>');
            e.$elem.find(".owl-wrapper").wrap('<div class="owl-wrapper-outer">');
            e.wrapperOuter = e.$elem.find(".owl-wrapper-outer");
            e.$elem.css("display", "block")
        },
        baseClass: function() {
            var e = this,
                t = e.$elem.hasClass(e.options.baseClass),
                n = e.$elem.hasClass(e.options.theme);
            if (!t) {
                e.$elem.addClass(e.options.baseClass)
            }
            if (!n) {
                e.$elem.addClass(e.options.theme)
            }
        },
        updateItems: function() {
            var t = this,
                n, r;
            if (t.options.responsive === false) {
                return false
            }
            if (t.options.singleItem === true) {
                t.options.items = t.orignalItems = 1;
                t.options.itemsCustom = false;
                t.options.itemsDesktop = false;
                t.options.itemsDesktopSmall = false;
                t.options.itemsTablet = false;
                t.options.itemsTabletSmall = false;
                t.options.itemsMobile = false;
                return false
            }
            n = e(t.options.responsiveBaseWidth).width();
            if (n > (t.options.itemsDesktop[0] || t.orignalItems)) {
                t.options.items = t.orignalItems
            }
            if (t.options.itemsCustom !== false) {
                t.options.itemsCustom.sort(function(e, t) {
                    return e[0] - t[0]
                });
                for (r = 0; r < t.options.itemsCustom.length; r += 1) {
                    if (t.options.itemsCustom[r][0] <= n) {
                        t.options.items = t.options.itemsCustom[r][1]
                    }
                }
            } else {
                if (n <= t.options.itemsDesktop[0] && t.options.itemsDesktop !== false) {
                    t.options.items = t.options.itemsDesktop[1]
                }
                if (n <= t.options.itemsDesktopSmall[0] && t.options.itemsDesktopSmall !== false) {
                    t.options.items = t.options.itemsDesktopSmall[1]
                }
                if (n <= t.options.itemsTablet[0] && t.options.itemsTablet !== false) {
                    t.options.items = t.options.itemsTablet[1]
                }
                if (n <= t.options.itemsTabletSmall[0] && t.options.itemsTabletSmall !== false) {
                    t.options.items = t.options.itemsTabletSmall[1]
                }
                if (n <= t.options.itemsMobile[0] && t.options.itemsMobile !== false) {
                    t.options.items = t.options.itemsMobile[1]
                }
            }
            if (t.options.items > t.itemsAmount && t.options.itemsScaleUp === true) {
                t.options.items = t.itemsAmount
            }
        },
        response: function() {
            var n = this,
                r, i;
            if (n.options.responsive !== true) {
                return false
            }
            i = e(t).width();
            n.resizer = function() {
                if (e(t).width() !== i) {
                    if (n.options.autoplay !== false && n.options.autoplay !== "false") {
                        t.clearInterval(n.autoplayInterval)
                    }
                    t.clearTimeout(r);
                    r = t.setTimeout(function() {
                        i = e(t).width();
                        n.updateVars()
                    }, n.options.responsiveRefreshRate)
                }
            };
            e(t).resize(n.resizer)
        },
        updatePosition: function() {
            var e = this;
            e.jumpTo(e.currentItem);
            if (e.options.autoplay !== false && e.options.autoplay !== "false") {
                e.checkAp()
            }
        },
        appendItemsSizes: function() {
            var t = this,
                n = 0,
                r = t.itemsAmount - t.options.items;
            t.$owlItems.each(function(i) {
                var s = e(this);
                s.css({
                    width: t.itemWidth
                }).data("owl-item", Number(i));
                if (i % t.options.items === 0 || i === r) {
                    if (!(i > r)) {
                        n += 1
                    }
                }
                s.data("owl-roundPages", n)
            })
        },
        appendWrapperSizes: function() {
            var e = this,
                t = e.$owlItems.length * e.itemWidth;
            e.$owlWrapper.css({
                width: t * 2,
                left: 0
            });
            e.appendItemsSizes()
        },
        calculateAll: function() {
            var e = this;
            e.calculateWidth();
            e.appendWrapperSizes();
            e.loops();
            e.max()
        },
        calculateWidth: function() {
            var e = this;
            e.itemWidth = Math.round(e.$elem.width() / e.options.items)
        },
        max: function() {
            var e = this,
                t = (e.itemsAmount * e.itemWidth - e.options.items * e.itemWidth) * -1;
            if (e.options.items > e.itemsAmount) {
                e.maximumItem = 0;
                t = 0;
                e.maximumPixels = 0
            } else {
                e.maximumItem = e.itemsAmount - e.options.items;
                e.maximumPixels = t
            }
            return t
        },
        min: function() {
            return 0
        },
        loops: function() {
            var t = this,
                n = 0,
                r = 0,
                i, s, o;
            t.positionsInArray = [0];
            t.pagesInArray = [];
            for (i = 0; i < t.itemsAmount; i += 1) {
                r += t.itemWidth;
                t.positionsInArray.push(-r);
                if (t.options.scrollPerPage === true) {
                    s = e(t.$owlItems[i]);
                    o = s.data("owl-roundPages");
                    if (o !== n) {
                        t.pagesInArray[n] = t.positionsInArray[i];
                        n = o
                    }
                }
            }
        },
        buildControls: function() {
            var t = this;
            if (t.options.navigation === true || t.options.pagination === true) {
                t.owlControls = e('<div class="owl-controls"/>').toggleClass("clickable", !t.browser.isTouch).appendTo(t.$elem)
            }
            if (t.options.pagination === true) {
                t.buildPagination()
            }
            if (t.options.navigation === true) {
                t.buildButtons()
            }
        },
        buildButtons: function() {
            var t = this,
                n = e('<div class="owl-buttons"/>');
            t.owlControls.append(n);
            t.buttonPrev = e("<div/>", {
                "class": "owl-prev",
                html: t.options.navigationText[0] || ""
            });
            t.buttonNext = e("<div/>", {
                "class": "owl-next",
                html: t.options.navigationText[1] || ""
            });
            n.append(t.buttonPrev).append(t.buttonNext);
            n.on("touchstart.owlControls mousedown.owlControls", 'div[class^="owl"]', function(e) {
                e.preventDefault()
            });
            n.on("touchend.owlControls mouseup.owlControls", 'div[class^="owl"]', function(n) {
                n.preventDefault();
                if (e(this).hasClass("owl-next")) {
                    t.next()
                } else {
                    t.prev()
                }
            })
        },
        buildPagination: function() {
            var t = this;
            t.paginationWrapper = e('<div class="owl-pagination"/>');
            t.owlControls.append(t.paginationWrapper);
            t.paginationWrapper.on("touchend.owlControls mouseup.owlControls", ".owl-page", function(n) {
                n.preventDefault();
                if (Number(e(this).data("owl-page")) !== t.currentItem) {
                    t.goTo(Number(e(this).data("owl-page")), true)
                }
            })
        },
        updatePagination: function() {
            var t = this,
                n, r, i, s, o, u;
            if (t.options.pagination === false) {
                return false
            }
            t.paginationWrapper.html("");
            n = 0;
            r = t.itemsAmount - t.itemsAmount % t.options.items;
            for (s = 0; s < t.itemsAmount; s += 1) {
                if (s % t.options.items === 0) {
                    n += 1;
                    if (r === s) {
                        i = t.itemsAmount - t.options.items
                    }
                    o = e("<div/>", {
                        "class": "owl-page"
                    });
                    u = e("<span></span>", {
                        text: t.options.paginationNumbers === true ? n : "",
                        "class": t.options.paginationNumbers === true ? "owl-numbers" : ""
                    });
                    o.append(u);
                    o.data("owl-page", r === s ? i : s);
                    o.data("owl-roundPages", n);
                    t.paginationWrapper.append(o)
                }
            }
            t.checkPagination()
        },
        checkPagination: function() {
            var t = this;
            if (t.options.pagination === false) {
                return false
            }
            t.paginationWrapper.find(".owl-page").each(function() {
                if (e(this).data("owl-roundPages") === e(t.$owlItems[t.currentItem]).data("owl-roundPages")) {
                    t.paginationWrapper.find(".owl-page").removeClass("active");
                    e(this).addClass("active")
                }
            })
        },
        checkNavigation: function() {
            var e = this;
            if (e.options.navigation === false) {
                return false
            }
            if (e.options.rewindNav === false) {
                if (e.currentItem === 0 && e.maximumItem === 0) {
                    e.buttonPrev.addClass("disabled");
                    e.buttonNext.addClass("disabled")
                } else if (e.currentItem === 0 && e.maximumItem !== 0) {
                    e.buttonPrev.addClass("disabled");
                    e.buttonNext.removeClass("disabled")
                } else if (e.currentItem === e.maximumItem) {
                    e.buttonPrev.removeClass("disabled");
                    e.buttonNext.addClass("disabled")
                } else if (e.currentItem !== 0 && e.currentItem !== e.maximumItem) {
                    e.buttonPrev.removeClass("disabled");
                    e.buttonNext.removeClass("disabled")
                }
            }
        },
        updateControls: function() {
            var e = this;
            e.updatePagination();
            e.checkNavigation();
            if (e.owlControls) {
                if (e.options.items >= e.itemsAmount) {
                    e.owlControls.hide()
                } else {
                    e.owlControls.show()
                }
            }
        },
        destroyControls: function() {
            var e = this;
            if (e.owlControls) {
                e.owlControls.remove()
            }
        },
        next: function(e) {
            var t = this;
            if (t.isTransition) {
                return false
            }
            t.currentItem += t.options.scrollPerPage === true ? t.options.items : 1;
            if (t.currentItem > t.maximumItem + (t.options.scrollPerPage === true ? t.options.items - 1 : 0)) {
                if (t.options.rewindNav === true) {
                    t.currentItem = 0;
                    e = "rewind"
                } else {
                    t.currentItem = t.maximumItem;
                    return false
                }
            }
            t.goTo(t.currentItem, e)
        },
        prev: function(e) {
            var t = this;
            if (t.isTransition) {
                return false
            }
            if (t.options.scrollPerPage === true && t.currentItem > 0 && t.currentItem < t.options.items) {
                t.currentItem = 0
            } else {
                t.currentItem -= t.options.scrollPerPage === true ? t.options.items : 1
            }
            if (t.currentItem < 0) {
                if (t.options.rewindNav === true) {
                    t.currentItem = t.maximumItem;
                    e = "rewind"
                } else {
                    t.currentItem = 0;
                    return false
                }
            }
            t.goTo(t.currentItem, e)
        },
        goTo: function(e, n, r) {
            var i = this,
                s;
            if (i.isTransition) {
                return false
            }
            if (typeof i.options.beforeMove === "function") {
                i.options.beforeMove.apply(this, [i.$elem])
            }
            if (e >= i.maximumItem) {
                e = i.maximumItem
            } else if (e <= 0) {
                e = 0
            }
            i.currentItem = i.owl.currentItem = e;
            if (i.options.transitionStyle !== false && r !== "drag" && i.options.items === 1 && i.browser.support3d === true) {
                i.swapSpeed(0);
                if (i.browser.support3d === true) {
                    i.transition3d(i.positionsInArray[e])
                } else {
                    i.css2slide(i.positionsInArray[e], 1)
                }
                i.afterGo();
                i.singleItemTransition();
                return false
            }
            s = i.positionsInArray[e];
            if (i.browser.support3d === true) {
                i.isCss3Finish = false;
                if (n === true) {
                    i.swapSpeed("paginationSpeed");
                    t.setTimeout(function() {
                        i.isCss3Finish = true
                    }, i.options.paginationSpeed)
                } else if (n === "rewind") {
                    i.swapSpeed(i.options.rewindSpeed);
                    t.setTimeout(function() {
                        i.isCss3Finish = true
                    }, i.options.rewindSpeed)
                } else {
                    i.swapSpeed("slideSpeed");
                    t.setTimeout(function() {
                        i.isCss3Finish = true
                    }, i.options.slideSpeed)
                }
                i.transition3d(s)
            } else {
                if (n === true) {
                    i.css2slide(s, i.options.paginationSpeed)
                } else if (n === "rewind") {
                    i.css2slide(s, i.options.rewindSpeed)
                } else {
                    i.css2slide(s, i.options.slideSpeed)
                }
            }
            i.afterGo()
        },
        jumpTo: function(e) {
            var t = this;
            if (typeof t.options.beforeMove === "function") {
                t.options.beforeMove.apply(this, [t.$elem])
            }
            if (e >= t.maximumItem || e === -1) {
                e = t.maximumItem
            } else if (e <= 0) {
                e = 0
            }
            t.swapSpeed(0);
            if (t.browser.support3d === true) {
                t.transition3d(t.positionsInArray[e])
            } else {
                t.css2slide(t.positionsInArray[e], 1)
            }
            t.currentItem = t.owl.currentItem = e;
            t.afterGo()
        },
        afterGo: function() {
            var e = this;
            e.prevArr.push(e.currentItem);
            e.prevItem = e.owl.prevItem = e.prevArr[e.prevArr.length - 2];
            e.prevArr.shift(0);
            if (e.prevItem !== e.currentItem) {
                e.checkPagination();
                e.checkNavigation();
                e.eachMoveUpdate();
                if (e.options.autoplay !== false && e.options.autoplay !== "false") {
                    e.checkAp()
                }
            }
            if (typeof e.options.afterMove === "function" && e.prevItem !== e.currentItem) {
                e.options.afterMove.apply(this, [e.$elem])
            }
        },
        stop: function() {
            var e = this;
            e.apStatus = "stop";
            t.clearInterval(e.autoplayInterval)
        },
        checkAp: function() {
            var e = this;
            if (e.apStatus !== "stop") {
                e.play()
            }
        },
        play: function() {
            var e = this;
            e.apStatus = "play";
            if (e.options.autoplay === false || e.options.autoplay === "false") {
                return false
            }
            t.clearInterval(e.autoplayInterval);
            e.autoplayInterval = t.setInterval(function() {
                e.next(true)
            }, e.options.autoplay)
        },
        swapSpeed: function(e) {
            var t = this;
            if (e === "slideSpeed") {
                t.$owlWrapper.css(t.addCssSpeed(t.options.slideSpeed))
            } else if (e === "paginationSpeed") {
                t.$owlWrapper.css(t.addCssSpeed(t.options.paginationSpeed))
            } else if (typeof e !== "string") {
                t.$owlWrapper.css(t.addCssSpeed(e))
            }
        },
        addCssSpeed: function(e) {
            return {
                "-webkit-transition": "all " + e + "ms ease",
                "-moz-transition": "all " + e + "ms ease",
                "-o-transition": "all " + e + "ms ease",
                transition: "all " + e + "ms ease"
            }
        },
        removeTransition: function() {
            return {
                "-webkit-transition": "",
                "-moz-transition": "",
                "-o-transition": "",
                transition: ""
            }
        },
        doTranslate: function(e) {
            return {
                "-webkit-transform": "translate3d(" + e + "px, 0px, 0px)",
                "-moz-transform": "translate3d(" + e + "px, 0px, 0px)",
                "-o-transform": "translate3d(" + e + "px, 0px, 0px)",
                "-ms-transform": "translate3d(" + e + "px, 0px, 0px)",
                transform: "translate3d(" + e + "px, 0px,0px)"
            }
        },
        transition3d: function(e) {
            var t = this;
            t.$owlWrapper.css(t.doTranslate(e))
        },
        css2move: function(e) {
            var t = this;
            t.$owlWrapper.css({
                left: e
            })
        },
        css2slide: function(e, t) {
            var n = this;
            n.isCssFinish = false;
            n.$owlWrapper.stop(true, true).animate({
                left: e
            }, {
                duration: t || n.options.slideSpeed,
                complete: function() {
                    n.isCssFinish = true
                }
            })
        },
        checkBrowser: function() {
            var e = this,
                r = "translate3d(0px, 0px, 0px)",
                i = n.createElement("div"),
                s, o, u, a;
            i.style.cssText = "  -moz-transform:" + r + "; -ms-transform:" + r + "; -o-transform:" + r + "; -webkit-transform:" + r + "; transform:" + r;
            s = /translate3d\(0px, 0px, 0px\)/g;
            o = i.style.cssText.match(s);
            u = o !== null && o.length === 1;
            a = "ontouchstart" in t || t.navigator.msMaxTouchPoints;
            e.browser = {
                support3d: u,
                isTouch: a
            }
        },
        moveEvents: function() {
            var e = this;
            if (e.options.mouseDrag !== false || e.options.touchDrag !== false) {
                e.gestures();
                e.disabledEvents()
            }
        },
        eventTypes: function() {
            var e = this,
                t = ["s", "e", "x"];
            e.ev_types = {};
            if (e.options.mouseDrag === true && e.options.touchDrag === true) {
                t = ["touchstart.owl mousedown.owl", "touchmove.owl mousemove.owl", "touchend.owl touchcancel.owl mouseup.owl"]
            } else if (e.options.mouseDrag === false && e.options.touchDrag === true) {
                t = ["touchstart.owl", "touchmove.owl", "touchend.owl touchcancel.owl"]
            } else if (e.options.mouseDrag === true && e.options.touchDrag === false) {
                t = ["mousedown.owl", "mousemove.owl", "mouseup.owl"]
            }
            e.ev_types.start = t[0];
            e.ev_types.move = t[1];
            e.ev_types.end = t[2]
        },
        disabledEvents: function() {
            var t = this;
            t.$elem.on("dragstart.owl", function(e) {
                e.preventDefault()
            });
            t.$elem.on("mousedown.disableTextSelect", function(t) {
                return e(t.target).is("input, textarea, select, option")
            })
        },
        gestures: function() {
            function s(e) {
                if (e.touches !== undefined) {
                    return {
                        x: e.touches[0].pageX,
                        y: e.touches[0].pageY
                    }
                }
                if (e.touches === undefined) {
                    if (e.pageX !== undefined) {
                        return {
                            x: e.pageX,
                            y: e.pageY
                        }
                    }
                    if (e.pageX === undefined) {
                        return {
                            x: e.clientX,
                            y: e.clientY
                        }
                    }
                }
            }

            function o(t) {
                if (t === "on") {
                    e(n).on(r.ev_types.move, a);
                    e(n).on(r.ev_types.end, f)
                } else if (t === "off") {
                    e(n).off(r.ev_types.move);
                    e(n).off(r.ev_types.end)
                }
            }

            function u(n) {
                var u = n.originalEvent || n || t.event,
                    a;
                if (u.which === 3) {
                    return false
                }
                if (r.itemsAmount <= r.options.items) {
                    return
                }
                if (r.isCssFinish === false && !r.options.dragBeforeAnimFinish) {
                    return false
                }
                if (r.isCss3Finish === false && !r.options.dragBeforeAnimFinish) {
                    return false
                }
                if (r.options.autoplay !== false && r.options.autoplay !== "false") {
                    t.clearInterval(r.autoplayInterval)
                }
                if (r.browser.isTouch !== true && !r.$owlWrapper.hasClass("grabbing")) {
                    r.$owlWrapper.addClass("grabbing")
                }
                r.newPosX = 0;
                r.newRelativeX = 0;
                e(this).css(r.removeTransition());
                a = e(this).position();
                i.relativePos = a.left;
                i.offsetX = s(u).x - a.left;
                i.offsetY = s(u).y - a.top;
                o("on");
                i.sliding = false;
                i.targetElement = u.target || u.srcElement
            }

            function a(o) {
                var u = o.originalEvent || o || t.event,
                    a, f;
                r.newPosX = s(u).x - i.offsetX;
                r.newPosY = s(u).y - i.offsetY;
                r.newRelativeX = r.newPosX - i.relativePos;
                if (typeof r.options.startDragging === "function" && i.dragging !== true && r.newRelativeX !== 0) {
                    i.dragging = true;
                    r.options.startDragging.apply(r, [r.$elem])
                }
                if ((r.newRelativeX > 8 || r.newRelativeX < -8) && r.browser.isTouch === true) {
                    if (u.preventDefault !== undefined) {
                        u.preventDefault()
                    } else {
                        u.returnValue = false
                    }
                    i.sliding = true
                }
                if ((r.newPosY > 10 || r.newPosY < -10) && i.sliding === false) {
                    e(n).off("touchmove.owl")
                }
                a = function() {
                    return r.newRelativeX / 5
                };
                f = function() {
                    return r.maximumPixels + r.newRelativeX / 5
                };
                r.newPosX = Math.max(Math.min(r.newPosX, a()), f());
                if (r.browser.support3d === true) {
                    r.transition3d(r.newPosX)
                } else {
                    r.css2move(r.newPosX)
                }
            }

            function f(n) {
                var s = n.originalEvent || n || t.event,
                    u, a, f;
                s.target = s.target || s.srcElement;
                i.dragging = false;
                if (r.browser.isTouch !== true) {
                    r.$owlWrapper.removeClass("grabbing")
                }
                if (r.newRelativeX < 0) {
                    r.dragDirection = r.owl.dragDirection = "left"
                } else {
                    r.dragDirection = r.owl.dragDirection = "right"
                }
                if (r.newRelativeX !== 0) {
                    u = r.getNewPosition();
                    r.goTo(u, false, "drag");
                    if (i.targetElement === s.target && r.browser.isTouch !== true) {
                        e(s.target).on("click.disable", function(t) {
                            t.stopImmediatePropagation();
                            t.stopPropagation();
                            t.preventDefault();
                            e(t.target).off("click.disable")
                        });
                        a = e._data(s.target, "events").click;
                        f = a.pop();
                        a.splice(0, 0, f)
                    }
                }
                o("off")
            }
            var r = this,
                i = {
                    offsetX: 0,
                    offsetY: 0,
                    baseElWidth: 0,
                    relativePos: 0,
                    position: null,
                    minSwipe: null,
                    maxSwipe: null,
                    sliding: null,
                    dargging: null,
                    targetElement: null
                };
            r.isCssFinish = true;
            r.$elem.on(r.ev_types.start, ".owl-wrapper", u)
        },
        getNewPosition: function() {
            var e = this,
                t = e.closestItem();
            if (t > e.maximumItem) {
                e.currentItem = e.maximumItem;
                t = e.maximumItem
            } else if (e.newPosX >= 0) {
                t = 0;
                e.currentItem = 0
            }
            return t
        },
        closestItem: function() {
            var t = this,
                n = t.options.scrollPerPage === true ? t.pagesInArray : t.positionsInArray,
                r = t.newPosX,
                i = null;
            e.each(n, function(s, o) {
                if (r - t.itemWidth / 20 > n[s + 1] && r - t.itemWidth / 20 < o && t.moveDirection() === "left") {
                    i = o;
                    if (t.options.scrollPerPage === true) {
                        t.currentItem = e.inArray(i, t.positionsInArray)
                    } else {
                        t.currentItem = s
                    }
                } else if (r + t.itemWidth / 20 < o && r + t.itemWidth / 20 > (n[s + 1] || n[s] - t.itemWidth) && t.moveDirection() === "right") {
                    if (t.options.scrollPerPage === true) {
                        i = n[s + 1] || n[n.length - 1];
                        t.currentItem = e.inArray(i, t.positionsInArray)
                    } else {
                        i = n[s + 1];
                        t.currentItem = s + 1
                    }
                }
            });
            return t.currentItem
        },
        moveDirection: function() {
            var e = this,
                t;
            if (e.newRelativeX < 0) {
                t = "right";
                e.playDirection = "next"
            } else {
                t = "left";
                e.playDirection = "prev"
            }
            return t
        },
        customEvents: function() {
            var e = this;
            e.$elem.on("owl.next", function() {
                e.next()
            });
            e.$elem.on("owl.prev", function() {
                e.prev()
            });
            e.$elem.on("owl.play", function(t, n) {
                e.options.autoplay = n;
                e.play();
                e.hoverStatus = "play"
            });
            e.$elem.on("owl.stop", function() {
                e.stop();
                e.hoverStatus = "stop"
            });
            e.$elem.on("owl.goTo", function(t, n) {
                e.goTo(n)
            });
            e.$elem.on("owl.jumpTo", function(t, n) {
                e.jumpTo(n)
            })
        },
        stopOnHover: function() {
            var e = this;
            if (e.options.stopOnHover === true && e.browser.isTouch !== true && e.options.autoplay !== false && e.options.autoplay !== "false") {
                e.$elem.on("mouseover", function() {
                    e.stop()
                });
                e.$elem.on("mouseout", function() {
                    if (e.hoverStatus !== "stop") {
                        e.play()
                    }
                })
            }
        },
        lazyLoad: function() {
            var t = this,
                n, r, i, s, o;
            if (t.options.lazyLoad === false) {
                return false
            }
            for (n = 0; n < t.itemsAmount; n += 1) {
                r = e(t.$owlItems[n]);
                if (r.data("owl-loaded") === "loaded") {
                    continue
                }
                i = r.data("owl-item");
                s = r.find(".lazyOwl");
                if (typeof s.data("src") !== "string") {
                    r.data("owl-loaded", "loaded");
                    continue
                }
                if (r.data("owl-loaded") === undefined) {
                    s.hide();
                    r.addClass("loading").data("owl-loaded", "checked")
                }
                if (t.options.lazyFollow === true) {
                    o = i >= t.currentItem
                } else {
                    o = true
                }
                if (o && i < t.currentItem + t.options.items && s.length) {
                    s.each(function() {
                        t.lazyPreload(r, e(this))
                    })
                }
            }
        },
        lazyPreload: function(e, n) {
            function o() {
                e.data("owl-loaded", "loaded").removeClass("loading");
                n.removeAttr("data-src");
                if (r.options.lazyEffect === "fade") {
                    n.fadeIn(400)
                } else {
                    n.show()
                }
                if (typeof r.options.afterLazyLoad === "function") {
                    r.options.afterLazyLoad.apply(this, [r.$elem])
                }
            }

            function u() {
                i += 1;
                if (r.completeImg(n.get(0)) || s === true) {
                    o()
                } else if (i <= 100) {
                    t.setTimeout(u, 100)
                } else {
                    o()
                }
            }
            var r = this,
                i = 0,
                s;
            if (n.prop("tagName") === "DIV") {
                n.css("background-image", "url(" + n.data("src") + ")");
                s = true
            } else {
                n[0].src = n.data("src")
            }
            u()
        },
        autoHeight: function() {
            function s() {
                var r = e(n.$owlItems[n.currentItem]).height();
                n.wrapperOuter.css("height", r + "px");
                if (!n.wrapperOuter.hasClass("autoHeight")) {
                    t.setTimeout(function() {
                        n.wrapperOuter.addClass("autoHeight")
                    }, 0)
                }
            }

            function o() {
                i += 1;
                if (n.completeImg(r.get(0))) {
                    s()
                } else if (i <= 100) {
                    t.setTimeout(o, 100)
                } else {
                    n.wrapperOuter.css("height", "")
                }
            }
            var n = this,
                r = e(n.$owlItems[n.currentItem]).find("img"),
                i;
            if (r.get(0) !== undefined) {
                i = 0;
                o()
            } else {
                s()
            }
        },
        completeImg: function(e) {
            var t;
            if (!e.complete) {
                return false
            }
            t = typeof e.naturalWidth;
            if (t !== "undefined" && e.naturalWidth === 0) {
                return false
            }
            return true
        },
        onVisibleItems: function() {
            var t = this,
                n;
            if (t.options.addClassActive === true) {
                t.$owlItems.removeClass("active")
            }
            t.visibleItems = [];
            for (n = t.currentItem; n < t.currentItem + t.options.items; n += 1) {
                t.visibleItems.push(n);
                if (t.options.addClassActive === true) {
                    e(t.$owlItems[n]).addClass("active")
                }
            }
            t.owl.visibleItems = t.visibleItems
        },
        transitionTypes: function(e) {
            var t = this;
            t.outClass = "owl-" + e + "-out";
            t.inClass = "owl-" + e + "-in"
        },
        singleItemTransition: function() {
            function a(e) {
                return {
                    position: "relative",
                    left: e + "px"
                }
            }
            var e = this,
                t = e.outClass,
                n = e.inClass,
                r = e.$owlItems.eq(e.currentItem),
                i = e.$owlItems.eq(e.prevItem),
                s = Math.abs(e.positionsInArray[e.currentItem]) + e.positionsInArray[e.prevItem],
                o = Math.abs(e.positionsInArray[e.currentItem]) + e.itemWidth / 2,
                u = "webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend";
            e.isTransition = true;
            e.$owlWrapper.addClass("owl-origin").css({
                "-webkit-transform-origin": o + "px",
                "-moz-perspective-origin": o + "px",
                "perspective-origin": o + "px"
            });
            i.css(a(s, 10)).addClass(t).on(u, function() {
                e.endPrev = true;
                i.off(u);
                e.clearTransStyle(i, t)
            });
            r.addClass(n).on(u, function() {
                e.endCurrent = true;
                r.off(u);
                e.clearTransStyle(r, n)
            })
        },
        clearTransStyle: function(e, t) {
            var n = this;
            e.css({
                position: "",
                left: ""
            }).removeClass(t);
            if (n.endPrev && n.endCurrent) {
                n.$owlWrapper.removeClass("owl-origin");
                n.endPrev = false;
                n.endCurrent = false;
                n.isTransition = false
            }
        },
        owlStatus: function() {
            var e = this;
            e.owl = {
                userOptions: e.userOptions,
                baseElement: e.$elem,
                userItems: e.$userItems,
                owlItems: e.$owlItems,
                currentItem: e.currentItem,
                prevItem: e.prevItem,
                visibleItems: e.visibleItems,
                isTouch: e.browser.isTouch,
                browser: e.browser,
                dragDirection: e.dragDirection
            }
        },
        clearEvents: function() {
            var r = this;
            r.$elem.off(".owl owl mousedown.disableTextSelect");
            e(n).off(".owl owl");
            e(t).off("resize", r.resizer)
        },
        unWrap: function() {
            var e = this;
            if (e.$elem.children().length !== 0) {
                e.$owlWrapper.unwrap();
                e.$userItems.unwrap().unwrap();
                if (e.owlControls) {
                    e.owlControls.remove()
                }
            }
            e.clearEvents();
            e.$elem.attr({
                style: e.$elem.data("owl-originalStyles") || "",
                "class": e.$elem.data("owl-originalClasses")
            })
        },
        destroy: function() {
            var e = this;
            e.stop();
            t.clearInterval(e.checkVisible);
            e.unWrap();
            e.$elem.removeData()
        },
        reinit: function(t) {
            var n = this,
                r = e.extend({}, n.userOptions, t);
            n.unWrap();
            n.init(r, n.$elem)
        },
        addItem: function(e, t) {
            var n = this,
                r;
            if (!e) {
                return false
            }
            if (n.$elem.children().length === 0) {
                n.$elem.append(e);
                n.setVars();
                return false
            }
            n.unWrap();
            if (t === undefined || t === -1) {
                r = -1
            } else {
                r = t
            }
            if (r >= n.$userItems.length || r === -1) {
                n.$userItems.eq(-1).after(e)
            } else {
                n.$userItems.eq(r).before(e)
            }
            n.setVars()
        },
        removeItem: function(e) {
            var t = this,
                n;
            if (t.$elem.children().length === 0) {
                return false
            }
            if (e === undefined || e === -1) {
                n = -1
            } else {
                n = e
            }
            t.unWrap();
            t.$userItems.eq(n).remove();
            t.setVars()
        }
    };
    e.fn.owlCarousel = function(t) {
        return this.each(function() {
            if (e(this).data("owl-init") === true) {
                return false
            }
            e(this).data("owl-init", true);
            var n = Object.create(r);
            n.init(t, this);
            e.data(this, "owlCarousel", n)
        })
    };
    e.fn.owlCarousel.options = {
        items: 5,
        itemsCustom: false,
        itemsDesktop: [1199, 4],
        itemsDesktopSmall: [979, 3],
        itemsTablet: [768, 2],
        itemsTabletSmall: false,
        itemsMobile: [479, 1],
        singleItem: false,
        itemsScaleUp: false,
        slideSpeed: 200,
        paginationSpeed: 800,
        rewindSpeed: 1e3,
        autoplay: false,
        stopOnHover: false,
        navigation: false,
        navigationText: ["prev", "next"],
        rewindNav: true,
        scrollPerPage: false,
        pagination: true,
        paginationNumbers: false,
        responsive: true,
        responsiveRefreshRate: 200,
        responsiveBaseWidth: t,
        baseClass: "owl-carousel",
        theme: "owl-theme",
        lazyLoad: false,
        lazyFollow: true,
        lazyEffect: "fade",
        autoHeight: false,
        jsonPath: false,
        jsonSuccess: false,
        dragBeforeAnimFinish: true,
        mouseDrag: true,
        touchDrag: true,
        addClassActive: false,
        transitionStyle: false,
        beforeUpdate: false,
        afterUpdate: false,
        beforeInit: false,
        afterInit: false,
        beforeMove: false,
        afterMove: false,
        afterAction: false,
        startDragging: false,
        afterLazyLoad: false
    }
})(jQuery, window, document)
jQuery(window).load(function(e) {
    jQuery("body").on("click", 'input[type="submit"]', function() {
        $form = jQuery(this).parents("form");
        form_action = $form.attr("target");
        form_class = $form.attr("class");
        id = $form.attr("id");
        if (form_class == "checkform") {
            var e = true;
            $form.find("label.req").each(function(t) {
                var n = jQuery(this).attr("for");
                defaultvalue = jQuery(this).html();
                value = $form.find("." + n).val();
                formtype = $form.find("." + n).attr("type");
                if (formtype == "radio" || formtype == "checkbox") {
                    if (jQuery("." + n + ":checked").length == 0) {
                        jQuery(this).siblings("div").find(".checkfalse").fadeIn(200);
                        e = false
                    } else {
                        jQuery(this).siblings("div").find(".checkfalse").fadeOut(200)
                    }
                } else if (n == "email") {
                    var r = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                    if (!value.match(r)) {
                        $form.find("." + n).addClass("false");
                        $form.find("." + n).parent(".form-row").addClass("false");
                        e = false
                    } else {
                        $form.find("." + n).removeClass("false");
                        $form.find("." + n).parent(".form-row").removeClass("false")
                    }
                } else {
                    if (value == "" || value == defaultvalue) {
                        $form.find("." + n).addClass("false");
                        $form.find("." + n).parent(".form-row").addClass("false");
                        e = false
                    } else {
                        $form.find("." + n).removeClass("false");
                        $form.find("." + n).parent(".form-row").removeClass("false")
                    }
                }
            });
            if (!e) {
                jQuery("#form-note").fadeIn(200);
                return false
            } else {
                jQuery("#form-note").fadeOut(200);
                if (form_action && form_action !== "") {
                    var t = $form.serialize();
                    jQuery.ajax({
                        type: "POST",
                        url: form_action,
                        data: t,
                        success: function(e) {
                            jQuery("#form-note").html(e);
                            jQuery("#form-note").delay(200).fadeIn(200)
                        }
                    });
                    return false
                } else {
                    return true
                }
            }
        }
    })
})

function initHeader() {
    var e = jQuery("header").height();
    var t = parseInt(jQuery("#page-content").css("padding-top"));
    if (jQuery(".non-overlay").length > 0) {
        jQuery("#pseudo-header").css({
            height: e + t * 2 + "px",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0
        })
    } else {
        jQuery("#pseudo-header").css({
            height: t * 2 + "px",
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            "z-index": "-1"
        });
        if (jQuery("header.overlay-bottom").length > 0) {
            setTimeout(function() {
                offsetTop = parseInt(jQuery("#page-content section").first().height() - e + t);
                jQuery("header").css({
                    top: offsetTop + "px"
                })
            }, 500)
        }
        headeroverlay = true
    }
    if (jQuery(".full-height").length > 0) {
        jQuery(".full-height").css({
            "min-height": jQuery(window).height() - t - t + "px"
        })
    }
    if (jQuery(".portfolio-carousel-item").length > 0) {
        if (parseInt(jQuery(".portfolio-carousel-item").css("height")) > jQuery(window).height() - t - t + 1 || parseInt(jQuery(".portfolio-carousel-item").css("height")) < jQuery(window).height() - t - t - 1) {
            jQuery(".portfolio-carousel-item").css({
                "min-height": jQuery(window).height() - t - t + "px"
            })
        }
    }
    if (jQuery(".portfolio-slider-item").length > 0) {
        if (parseInt(jQuery(".portfolio-slider-item").css("height")) > jQuery(window).height() - t - t + 1 || parseInt(jQuery(".portfolio-slider-item").css("height")) < jQuery(window).height() - t - t - 1) {
            jQuery(".portfolio-slider-item").css({
                "min-height": jQuery(window).height() - t - t + "px"
            })
        }
    }
}

function fixHeader() {
    if (jQuery("header.non-sticky").length < 1 && jQuery("#page-loader").css("display") !== "block" && !headerStickyOnLoad) {
        if (jQuery("header").is(":first-child")) {
            headerTopPos = 0 + borderWidthHeight
        } else {
            headerTopPos = jQuery("#page-content section").first().height() + borderWidthHeight
        }
        if (headeroverlay) {
            if (jQuery("header.overlay-bottom").length < 1) {
                headerTopPos = 0 + borderWidthHeight
            } else {
                headerTopPos = jQuery("#page-content section").first().height() + borderWidthHeight - headerHeight
            }
        }
        var e = jQuery(window).scrollTop();
        if (e - headerHeight + borderWidthHeight > headerTopPos) {
            jQuery("header").addClass("sticky-header");
            if (jQuery("#sticky-header-pseudo").length < 1 && !headeroverlay) {
                jQuery("header").after('<div id="sticky-header-pseudo"></div>');
                jQuery("#sticky-header-pseudo").css({
                    height: headerHeight + "px"
                })
            } else if (headeroverlay) {
                jQuery("#sticky-header-pseudo").remove()
            }
            // if (borderWidthHeight > 0 && !footerStickyOnLoad) {
            //     jQuery("footer").addClass("sticky-footer")
            // }
        } else {
            jQuery("header").removeClass("sticky-header");
            if (jQuery("#sticky-header-pseudo").length > 0 && !headeroverlay) {
                jQuery("#sticky-header-pseudo").remove()
            }
            // if (borderWidthHeight > 0 && !footerStickyOnLoad) {
            //     jQuery("footer").removeClass("sticky-footer")
            // }
        }
    }
    if (jQuery(window).scrollTop() > 100) {
        jQuery("footer").addClass("sticky-footer")
    } else {
        jQuery("footer").removeClass("sticky-footer")
    }
}
var headerHeight = jQuery("header").height();
var headerTopPos = jQuery("header").offset().top;
var headerStickyOnLoad = false;
var footerStickyOnLoad = false;
var headeroverlay = false;
var borderWidthHeight = parseInt(jQuery("#page-content").css("padding-top"));
jQuery(window).load(function(e) {
    var t = jQuery("header").height();
    if (jQuery(".sticky-header").length > 0) {
        headerStickyOnLoad = true
    }
    if (jQuery(".sticky-footer").length > 0) {
        footerStickyOnLoad = true
    }
    jQuery("body").append('<div id="pseudo-header"></div>');
    initHeader()
});
jQuery(window).resize(function() {
    initHeader()
});
jQuery(window).scroll(function() {
    fixHeader()
})

function smoothShow() {
    jQuery("h1[data-bigletter],h2[data-bigletter],h3[data-bigletter],h4[data-bigletter],h5[data-bigletter],h6[data-bigletter]").each(function() {
        if (jQuery(window).width() > 700) {
            var e = jQuery(this).visible(false);
            if (e) {
                if (jQuery(this).hasClass("visible")) {} else {
                    jQuery(this).addClass("visible")
                }
            } else {
                jQuery(this).removeClass("visible")
            }
        } else {
            jQuery(this).addClass("visible")
        }
    });
    jQuery(".counter-value").each(function() {
        if (jQuery(window).width() > 700) {
            var e = jQuery(this).visible(false);
            if (jQuery(this).hasClass("anim")) {} else if (e) {
                jQuery(this).addClass("anim");
                var t = parseInt(jQuery(this).attr("data-from"));
                var n = parseInt(jQuery(this).attr("data-to"));
                var r = parseInt(jQuery(this).attr("data-speed"));
                jQuery(this).count(t, n, r)
            }
        } else {
            var n = parseInt(jQuery(this).attr("data-to"));
            jQuery(this).html(n)
        }
    });
    jQuery(".sr-animation").each(function() {
        if (jQuery(window).width() > 700) {
            var e = jQuery(this).visible(true);
            var t = jQuery(this).attr("data-delay");
            if (!t) {
                t = 0
            }
            if (jQuery(this).hasClass("animated")) {} else if (e) {
                jQuery(this).delay(t).queue(function() {
                    jQuery(this).addClass("animated")
                })
            }
        } else {
            jQuery(this).addClass("animated")
        }
    });
    jQuery(".skill").each(function() {
        var e = jQuery(this).visible(true);
        var t = jQuery(this).find(".skill-bar .skill-active ").attr("data-perc");
        if (jQuery(this).hasClass("anim")) {} else if (e) {
            var n = Math.floor(Math.random() * (300 - 50 + 1)) + 50;
            jQuery(this).addClass("anim");
            jQuery(this).find(".skill-bar .skill-active ").animate({
                width: t + "%"
            }, 2e3, "easeInOutQuart", function() {
                jQuery(this).find(".tooltip").delay(n).animate({
                    top: "-25px",
                    right: "-8px",
                    opacity: 1
                }, 500)
            }).css("overflow", "visible")
        }
    })
}

function splitSection() {
    var e = parseInt(jQuery("#bodyborder-top").height());
    if (jQuery(".split-section").length > 0) {
        contentWidth = jQuery(".wrapper").width();
        if (!contentWidth || contentWidth < 300) {
            contentWidth = 1080;
            if (jQuery(window).width() < 1281) {
                contentWidth = 900
            } else if (jQuery(window).width() < 1121) {
                contentWidth = 730
            } else if (jQuery(window).width() < 861) {
                contentWidth = 280
            }
        }
        contentThird = Math.round(contentWidth / 3);
        windowWidth = jQuery(window).width() - e * 2;
        difference = Math.round((windowWidth - contentWidth) / 2);
        smallWidth = contentThird + difference + 13;
        bigWidth = windowWidth - smallWidth;
        if (jQuery(window).width() < 861) {
            jQuery(".split-onethird, .split-onethird .split-bg, .split-twothird, .split-twothird .split-bg").css({
                width: "100%"
            })
        } else {
            jQuery(".split-onethird, .split-onethird .split-bg").css({
                width: smallWidth + "px"
            });
            jQuery(".split-twothird, .split-twothird .split-bg").css({
                width: bigWidth + "px"
            })
        }
        setTimeout(function() {
            jQuery(".split-section .vertical-center").each(function(e, t) {
                var n = jQuery(this).height();
                var r = parseInt(jQuery(this).css("padding-top")) + parseInt(jQuery(this).css("padding-bottom"));
                var i = n + r;
                var s = jQuery(this).parents(".split-section").height();
                if (i < s && jQuery(window).width() > 861) {
                    var o = (s - i) / 2;
                    jQuery(this).css({
                        marginTop: o + "px"
                    })
                } else {
                    jQuery(this).css({
                        marginTop: "0px"
                    })
                }
            })
        }, 500)
    }
    if (jQuery(window).width() < 861) {
        jQuery(".split-left, .split-right").each(function(e, t) {
            var n = jQuery(this).height();
            if (n < 50) {
                jQuery(this).css({
                    "min-height": "300px"
                })
            }
        })
    }
}
setTimeout(function() {
    jQuery("body").addClass("loading")
}, 200);
jQuery(window).load(function(e) {
    function r(e) {
        jQuery("#page-loader").slideDown(800, "easeInOutExpo", function() {
            setTimeout(function() {
                window.location = e
            }, 300)
        });
        setTimeout(function() {
            jQuery("body").removeClass("loading-end")
        }, 500)
    }

    function s() {
        jQuery(".open-nav span").toggleClass("is-clicked");
        jQuery("#main-nav").removeClass("nav-visible");
        jQuery("body").removeClass("nav-visible");
        jQuery(".nav-inner").animate({
            marginTop: "0px",
            opacity: 0
        }, 700, "easeInOutExpo", function() {});
        jQuery("#main-nav").delay(100).slideUp(700, "easeInOutExpo")
    }
    splitSection();
    jQuery(window).resize(function() {
        splitSection()
    });
    var t = window.location.hash.substr(1);
    var n = parseInt(jQuery("#bodyborder-top").height());
    jQuery("#page-loader .page-loader-inner").delay(500).fadeIn(10, function() {
        jQuery("body").addClass("loading-end");
        jQuery("#page-loader .page-loader-inner").fadeOut(1e3, function() {
            if (t) {
                jQuery("html,body").animate({
                    scrollTop: jQuery("#" + t).offset().top - jQuery("header").height() + 80
                }, 10, "easeInOutExpo")
            }
        });
        jQuery("#page-loader").delay(1300).animate({
            top: n + "px",
            height: jQuery(window).height() - n * 2 + "px"
        }, 10).slideUp(1e3, "easeInOutExpo", function() {
            jQuery("#page-loader").animate({
                top: "0",
                height: "100%"
            }, 10)
        })
    });
    jQuery(window).unload(function() {});
    jQuery(".transition, .entries-pagination li a").click(function(e) {
        href = jQuery(this).attr("href");
        if (href.charAt(0) !== "#") {
            r(href);
            return false
        } else {
            return true
        }
    });
    // if (jQuery().isotope) {
    //     jQuery(".masonry").each(function() {
    //         var e = jQuery(this);
    //         e.imagesLoaded(function() {
    //             e.isotope({
    //                 itemSelector: ".masonry-item",
    //                 transformsEnabled: true
    //             })
    //         })
    //     });
    //     jQuery(".filter li a").click(function() {
    //         var e = jQuery(this).parents("ul.filter").data("related-grid");
    //         jQuery(this).parents("ul.filter").find("li a").removeClass("active");
    //         jQuery(this).addClass("active");
    //         var t = jQuery(this).attr("data-option-value");
    //         jQuery("#" + e).isotope({
    //             filter: t
    //         }, function() {});
    //         return false
    //     });

    //     function i() {
    //         jQuery(".masonry").each(function() {
    //             $container = jQuery(this);
    //             var e = $container.data("maxitemwidth");
    //             if (!e) {
    //                 e = 370
    //             }
    //             var t = Math.ceil(($container.width() + parseInt($container.css("marginLeft")) * 2) / 120 * 100 - parseInt($container.css("marginLeft")) * 2);
    //             var n = parseInt($container.children("div").css("marginRight")) + parseInt($container.children("div").css("marginLeft"));
    //             var r = Math.ceil(t / e);
    //             var i = (r - 1) * n;
    //             var s = i / r;
    //             var o = Math.floor(t / r - s + 1);
    //             $container.children("div").css({
    //                 width: o + "px"
    //             });
    //             if ($container.children("div").hasClass("isotope-item")) {
    //                 $container.isotope("reLayout")
    //             }
    //         })
    //     }
    //     i();
    //     jQuery(window).resize(function() {
    //         i()
    //     })
    // }
    jQuery("nav#main-nav ul").on("click", "li", function() {
        if (jQuery(window).width() < 1025) {
            if (jQuery(this).find("ul").length > 0) {
                if (jQuery(this).find("ul").css("visibility") == "hidden") {
                    jQuery(this).addClass("hovered");
                    return false
                } else {
                    jQuery(this).removeClass("hovered");
                    return false
                }
            }
        }
        var e = jQuery(this).find("a").attr("href");
        if (e.charAt(0) !== "#") {
            r(e);
            return false
        } else {
            s();
            return true
        }
    });
    jQuery("header").on("click", ".open-nav", function() {
        var e = jQuery("#main-nav").css("display");
        var t = parseInt(jQuery("#page-content").css("padding-top"));
        var n = jQuery(window).height() - t * 2;
        if (e == "block") {
            s()
        } else {
            jQuery(".open-nav span").toggleClass("is-clicked");
            jQuery("#main-nav").slideDown(700, "easeInOutExpo", function() {
                jQuery("body").addClass("nav-visible");
                jQuery("#main-nav").addClass("nav-visible");
                var e = jQuery(".nav-inner").height();
                jQuery(".nav-inner").css({
                    "max-height": e + "px"
                });
                if (e < n) {
                    var t = parseInt((n - e) / 2)
                } else {
                    var t = 0
                }
                jQuery(".nav-inner").animate({
                    marginTop: t + "px",
                    opacity: 1
                }, 700, "easeInOutQuart")
            })
        }
        return false
    });
    jQuery("body").on("click", ".show-share", function() {
        if (jQuery(window).width() < 1025) {
            if (parseInt(jQuery(this).siblings("ul").css("top")) < 0) {
                jQuery(this).parent("#social-share").addClass("hovered");
                return false
            } else {
                jQuery(this).parent("#social-share").removeClass("hovered");
                return false
            }
            return false
        }
        return false
    });
    jQuery(".tabs").each(function(e) {
        jQuery(this).find(".tab-content").removeClass("active");
        var t = jQuery(this).find(".active").attr("href");
        jQuery(this).find("." + t).addClass("active")
    });
    jQuery(".tab-nav").on("click", "a", function() {
        var e = jQuery(this).parent("li").parent("ul").parent("div");
        var t = jQuery(this).attr("href");
        jQuery(e).find(".tab-nav a").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(e).find(".tab-container .tab-content").hide().removeClass("active");
        jQuery(e).find(".tab-container ." + t).fadeIn(500).addClass("active");
        return false
    });
    jQuery(".toggle-item").each(function(e) {
        jQuery(this).find(".toggle-active").siblings(".toggle-inner").slideDown(300)
    });
    jQuery(".toggle-item").on("click", ".toggle-title", function() {
        var e = jQuery(this).parent("div").parent("div");
        var t = jQuery(this).parent("div").find(".toggle-inner").css("display");
        if (jQuery(e).attr("class") == "accordion") {
            if (t !== "none") {
                jQuery(e).find(".toggle-item .toggle-inner").slideUp(300);
                jQuery(this).toggleClass("toggle-active")
            } else {
                jQuery(e).find(".toggle-item .toggle-inner").slideUp(300);
                jQuery(e).find(".toggle-item .toggle-title").removeClass("toggle-active");
                jQuery(this).toggleClass("toggle-active");
                jQuery(this).siblings(".toggle-inner").slideDown(300)
            }
        } else {
            jQuery(this).toggleClass("toggle-active");
            jQuery(this).siblings(".toggle-inner").slideToggle(300)
        }
        return false
    });
    jQuery("#backtotop").click(function() {
        jQuery("html, body").animate({
            scrollTop: 0
        }, 1e3, "easeInOutQuart");
        return false
    });
    if (jQuery().revolution) {
        jQuery(".rev-slider").revolution({
            delay: 1e4,
            startheight: 500,
            startwidth: 1200,
            hideTimerBar: "on",
            onHoverStop: "on",
            navigationType: "bullet",
            hideThumbs: 0,
            navigationHAlign: "right",
            navigationVAlign: "center",
            navigationHOffset: 20,
            navigationVOffset: 0,
            navigationArrows: "none",
            fullWidth: "off",
            fullScreen: "on",
            fullScreenOffsetContainer: "#pseudo-header"
        })
    }
    if (jQuery().owlCarousel) {
        jQuery(".owlslider").owlCarousel({
            autoPlay: false,
            stopOnHover: true,
            navigation: false,
            navigationText: false,
            rewindNav: false,
            slideSpeed: 800,
            paginationSpeed: 800,
            singleItem: true,
            autoHeight: true
        });
        jQuery(".owlcarousel").owlCarousel({
            items: 4,
            itemsDesktop: false,
            itemsDesktopSmall: false,
            itemsTablet: [860, 2],
            itemsMobile: [640, 1],
            autoplay: false,
            autoHeight: true,
            navigationText: false
        })
    }
    if (jQuery().parallax) {
        jQuery(".parallax-section").parallax()
    }
    if (jQuery().bgVideo) {
        setTimeout(function() {
            jQuery(".videobg-section").bgVideo()
        }, 1e3)
    }
    if (jQuery().fancybox) {
        jQuery(".openfancybox").fancybox({
            openEffect: "fade",
            closeEffect: "fade"
        })
    }
    // if (jQuery().fitVids) {
    //     jQuery("body").fitVids()
    // }
    if (jQuery().jPlayer && jQuery(".jp-interface").length) {
        jQuery(".jp-interface").each(function() {
            var e = jQuery(this).width();
            var t = e - 175;
            jQuery(this).find(".jp-progress-container").css({
                width: t + "px"
            })
        })
    }
    smoothShow()
});
jQuery(window).scroll(function() {
    smoothShow()
})

jQuery(window).load(function(){
	
	jQuery("body").on("click",".tooltip-option",function(){ 
		var test = jQuery(this).data("addclass");
		
		jQuery(".tooltip-option").removeClass("selected");
		jQuery(this).addClass("selected");
		
		jQuery("body").removeClass("bordered");
		jQuery("body").removeClass("big-border");
		jQuery("body").removeClass("no-border");
		jQuery("body").addClass(test);
		
		
		// /*isotope*/
		// function reorganizeIsotope() {
		// 	jQuery('.masonry').each(function(){
		// 		$container = jQuery(this);
		// 		var maxitemwidth = $container.data('maxitemwidth');
		// 		if (!maxitemwidth) { maxitemwidth = 370; }
		// 		var containerwidth = Math.ceil((($container.width()+(parseInt($container.css('marginLeft'))*2)) / 120) * 100 - (parseInt($container.css('marginLeft'))*2));
		// 		//alert(containerwidth);
		// 		var itemmargin = parseInt($container.children('div').css('marginRight')) + parseInt($container.children('div').css('marginLeft'));
		// 		var rows = Math.ceil(containerwidth/maxitemwidth);
		// 		var marginperrow = (rows-1)*itemmargin;
		// 		var newitemmargin = marginperrow / rows;
		// 		var itemwidth = Math.floor((containerwidth/rows)-newitemmargin+1);
		// 		//$container.css({ 'width': '110%' });
		// 		$container.children('div').css({ 'width': itemwidth+'px' });
		// 		if ($container.children('div').hasClass('isotope-item')) { $container.isotope( 'reLayout' ); }
		// 	});
		// }
		//reorganizeIsotope();
		
		initHeader();
		
		return false;
	});
	
});